import { http, createConfig, createStorage } from "@wagmi/vue";
import { coinbaseWallet, walletConnect, injected, safe } from "@wagmi/vue/connectors";
import {
  mainnet,
  optimism,
  bsc,
  polygon,
  arbitrum,
  base,
  avalanche,
  celo,
  zora,
} from "@wagmi/vue/chains";

export const chains = [
  mainnet,
  optimism,
  bsc,
  polygon,
  arbitrum,
  base,
  avalanche,
  celo,
  zora
];

const transports = {
  [mainnet.id]: http(),
  [optimism.id]: http(),
  [bsc.id]: http(),
  [polygon.id]: http(),
  [arbitrum.id]: http(),
  [base.id]: http(),
  [avalanche.id]: http(),
  [celo.id]: http(),
  [zora.id]: http(),
};


const wcProjectId = __CONFIG.WC_PROJECT_ID

export const config = createConfig({
  chains,
  connectors: [
    walletConnect({
      projectId: wcProjectId,
    }),
    coinbaseWallet({ appName: "Vite Vue Playground", darkMode: true }),
    injected(),
    safe()
  ],
  storage: createStorage({ storage: localStorage, key: "vite-vue" }),
  transports,
});

declare module "@wagmi/vue" {
  interface Register {
    config: typeof config;
  }
}
