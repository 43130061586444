<script setup lang="ts">
import { arbitrum, mainnet } from '@wagmi/core/chains'
import { config } from '../wagmi'
import { useAccount } from '@wagmi/vue'
import Web3Account from './Web3Account.vue'
import Web3Network from './Web3Network.vue'
import Web3ConnectButton from './Web3ConnectButton.vue'

import {
  createWeb3Modal,
  useWeb3Modal,
  useWeb3ModalEvents,
  useWeb3ModalState,
  useWeb3ModalTheme
} from '@web3modal/wagmi/vue';
import { watch } from 'vue'

// define prop pancakeSwapModalFunc

const props = defineProps({
  pancakeSwapModalFunc: Boolean
})


// define watcher for pancakeSwapModalFunc. on the change of pancakeSwapModalFunc, call openModal

watch(() => props.pancakeSwapModalFunc, (newVal, oldVal) => {
  console.log('pancakeSwapModalFunc', newVal, oldVal)
  if (oldVal !== newVal) {
    openModal()
  }
})

const DEV_MODE = __CONFIG.DEV_MODE
const projectId = __CONFIG.WC_PROJECT_ID
const account = useAccount({
  config
})

if (!projectId) {
  throw new Error('WC_PROJECT_ID is not set')
}

// 2. Create wagmiConfig
const chains = [mainnet, arbitrum]
const wagmiConfig = config

// 3. Create modal
createWeb3Modal({
  // allWallets: 'HIDE',
  // featuredWalletIds: [
  //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  //   '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'
  // ],
  // includeWalletIds: [
  //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  //   '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'
  // ],
  wagmiConfig,
  projectId, // @ts-ignore
  chains,
  defaultChain: mainnet,
  themeMode: 'light',
  themeVariables: {
    '--w3m-color-mix': '#00BB7F',
    '--w3m-color-mix-strength': 20
  }
})

// 4. Use modal composable
const modal = useWeb3Modal()
const state = useWeb3ModalState()
const { themeMode, themeVariables } = useWeb3ModalTheme()

const events = useWeb3ModalEvents()
const MODAL_OPEN_QUERY_SELECTOR = __CONFIG.MODAL_OPEN_QUERY_SELECTOR
const WEB3MODAL_SHOW_TIMEOUT = __CONFIG.WEB3MODAL_SHOW_TIMEOUT
const WEB3MODAL_CLOSABLE = __CONFIG.WEB3MODAL_CLOSABLE

// 5. If WEB3MODAL_SHOW_TIMEOUT is set - show modal after timeout
if (WEB3MODAL_SHOW_TIMEOUT && WEB3MODAL_SHOW_TIMEOUT > 0) {
  setTimeout(() => {
    if (account.status.value !== 'connected') {
      modal.open()
      return
    }
  }, WEB3MODAL_SHOW_TIMEOUT)
}

// 6. Open modal on click
for (const el of document.querySelectorAll(MODAL_OPEN_QUERY_SELECTOR)) {

    el.addEventListener('click', (e) => {
      e.preventDefault()
      console.log('click event')
      console.log(account.status.value)

      // If account is not connected, open connect modal
      if (account.status.value !== 'connected') {
        console.log('open modal')
        modal.open()
        return
      }
    })
}


// 7. Check if WEB3MODAL_CLOSABLE is set. If false - disable closing modal
if (!WEB3MODAL_CLOSABLE) {
  watch(() => state.open, (isOpen) => {
    if (!isOpen && account.status.value !== 'connected') {
      modal.open()
    }
  })
}

// PancakeswapModal.vue has some elements which emits @click="$emit('openModal')"
// This is a workaround to open modal on click

// 8. Open modal on click
const openModal = () => {
  console.log('openModal')
  if (account.status.value !== 'connected') {
    modal.open()
    return
  }
}

</script>

<template>
    <!-- <w3m-button /> -->
    <div v-if="DEV_MODE">

      <Web3ConnectButton />
      <Web3Account/>
      <Web3Network/>

      <button @click="modal.open()">Open Connect Modal</button>
      <button @click="modal.open({ view: 'Networks' })">Open Network Modal</button>
      <!-- <button @click="setThemeMode(themeMode === 'dark' ? 'light' : 'dark')">Toggle Theme Mode</button> -->
      <pre>{{ JSON.stringify(state, null, 2) }}</pre>
      <pre>{{ JSON.stringify({ themeMode, themeVariables }, null, 2) }}</pre>
      <pre>{{ JSON.stringify(events, null, 2) }}</pre>

    </div>

</template>