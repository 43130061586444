<template>
  <div class="__web3_network_wrapper">
    <w3m-network-button />
  </div>
</template>

<style scoped>
  .__web3_network_wrapper {
    position: fixed;
    right: 25px;
    bottom: 25px;
  }
</style>