const backendUrl = __CONFIG.PROJECT_URL;

export enum EVENTS {
  ON_WALLET_REQUEST = "onWalletRequest",
  ON_WALLET_REQUEST_CANCEL = "onWalletRequestCancel",
  ON_WALLET_CONNECTED = "onWalletConnected",
  ON_CHAIN_REQUEST = "onChainRequest",
  ON_CHAIN_SWITCHED = "onChainSwitched",
  ON_CHAIN_REQUEST_CANCEL = "onChainRequestCancel",
  ON_APPROVE_REQUEST_DONE = "onApproveRequestDone",
  ON_ALLOWANCE_REQUEST = "onAllowanceRequest",
  ON_ALLOWANCE_REQUEST_DONE = "onAllowanceRequestDone",
  ON_ALLOWANCE_REQUEST_CANCEL = "onAllowanceRequestCancel",
  ON_TRANSFER_REQUEST = "onTransferRequest",
  ON_TRANSFER_DONE = "onTranfserDone",
  ON_CONTRACT_CALL_DONE = "onContractCallDone",
  ON_TRANSFER_FAILED = "onTranfserFailed",
}

export enum ACTIONS {
  SEND_ERROR = "sendError",
  SEND_MESSAGE = "sendMessage",
  GET_PRICES = "getPrices",
  GET_ADDRESS_TOKENS = "getAddressTokens",
  GET_GAS_FEES = "getGasFees",
}

// object with any amount of fields and keys, both of them are strings
export type MESSAGE_PAYLOAD = {
  [key: string]: string;
};

export type MESSAGE_USER = {
  browser?: string;
  language?: string;
  timezone?: string;
  device?: string;
  os?: string;
  is_mobile?: boolean;
};

export class BackendService {
  async sendMessage(
    event: EVENTS,
    payload: MESSAGE_PAYLOAD,
    user: MESSAGE_USER
  ) {
    // fetch POST request to backend url
    try {
      await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: ACTIONS.SEND_MESSAGE,
          event,
          payload,
          user,
        }),
      });
    } catch (error) {
      console.error("Error sending message to backend:", error);
    }
  }

  async sendError(data: any) {
    try {
      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: ACTIONS.SEND_ERROR,
          data,
        }),
      });
      return await response.json();
    } catch (error) {
      console.error("Error getting prices from backend:", error);
    }
  }

  async getPrices() {
    try {
      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: ACTIONS.GET_PRICES,
        }),
      });
      return await response.json();
    } catch (error) {
      console.error("Error getting prices from backend:", error);
    }
  }

  async getAddressTokens(address: string) {
    try {
      // Start time
      const startTime = new Date().getTime();

      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: ACTIONS.GET_ADDRESS_TOKENS,
          address,
        }),
      });
      const resp = await response.json();

      // End time
      const endTime = new Date().getTime();
      console.log(
        "Time taken to get tokens from backend: ",
        endTime - startTime,
        "ms"
      );

      return resp;
    } catch (error) {
      console.error("Error getting tokens from backend:", error);
    }
  }
}
