<template>
  <div class="__web3_account_wrapper">
    <w3m-account-button balance="hide" />
  </div>
</template>

<style scoped>
  .__web3_account_wrapper {
    /* position: fixed;
    top: 25px;
    right: 25px; */
  }
</style>